import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import Breadcrumbs from './breadcrumbs';
import Title from './title';
import LazyImage from './lazy-image';
import Container from './container';
import { darkBackground } from '../utils/styles';
import { vw } from '../utils/size-functions';
import mq from '../utils/mq';
import WipeAnimation from './wipe-animation';
import { VideoPlayer } from './video-player'

const IMAGE_OFFSET = 390;
const IMAGE_OFFSET_TABLET = 280;
const IMAGE_OFFSET_MOBILE = 240;

const StyledIntro3 = styled(Box)`
  ${darkBackground};
  margin-bottom: ${IMAGE_OFFSET}px;
  padding-bottom: 100px;

  ${mq.tablet(css`
    margin-bottom: ${IMAGE_OFFSET_TABLET}px;
    padding-bottom: 100px;
  `)};
  ${mq.mobile(css`
    margin-bottom: ${IMAGE_OFFSET_TABLET}px;
    padding-bottom: 50px;
  `)};
  ${mq.mobileLarge(css`
    margin-bottom: ${IMAGE_OFFSET_TABLET}px;
    padding-bottom: 50px;
  `)};

  @media (max-width: 425px) {
    margin-bottom: ${IMAGE_OFFSET_MOBILE}px;
    padding-bottom: 25px;
  }
`;

const Image = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
`;




function Intro3WithVideo({ pagination, title, text, image, video }) {
  return (
    <StyledIntro3 pt={[80, 100, 125]}>
      <Container>
        <Flex flexDirection={'column'} alignItems={'center'} mb={88}>
          <Breadcrumbs mb={20} items={pagination} white />
          <Title
            as={'h1'}
            maxWidth={650}
            mb={15}
            textAlign={'center'}
            html={title}
          />
          <Paragraph lightGrey textAlign={'center'} html={text} />
        </Flex>
        <WipeAnimation
          mt={[-IMAGE_OFFSET_MOBILE, -IMAGE_OFFSET_TABLET, -IMAGE_OFFSET]}
          bottom={[-IMAGE_OFFSET_MOBILE, -IMAGE_OFFSET_TABLET, -IMAGE_OFFSET]}
          width={'100%'}
          height={[IMAGE_OFFSET_MOBILE, IMAGE_OFFSET_TABLET, vw(580)]}
          threshold={0}
          maxHeight={video ? 600 : undefined}
        >
          {image && (
            <Image fluid={image} />
          )}
          {video && (
            <VideoPlayer videoSource={video.filename} />
          )}
        </WipeAnimation>
      </Container>
    </StyledIntro3>
  );
}

export default Intro3WithVideo;
