import type { FC, SyntheticEvent } from "react"
import React, { useCallback, useState } from "react"
import ButtonPlay from "./button-play"
import VideoPlayerOverlay from "./video-player-overlay"
import styled, { css } from "styled-components"

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-bottom: 8px;

  background-color: #000;
  user-select: all;
`

const VideoContainerInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;

  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`

const VideoContainerButton = styled(ButtonPlay)`
  grid-column: 1;
  grid-row: 1;
  z-index: 1;

  justify-content: center;
`

const VideoContainerAutoPlay = styled.video<Pick<Props, 'fit'>>`
  grid-column: 1;
  grid-row: 1;
  z-index: 0;

  filter: brightness(0.6);
  height: 100%;
  width: 100%;

  ${(props) => css`object-fit: ${props.fit ?? 'cover'};`}

`

interface Props {
  videoSrc: string
  fit?: 'cover' | 'contain'
  autoPlay?: boolean
}

export const VideoPreviewWithOverlay: FC<Props> = ({ videoSrc, fit = 'cover', autoPlay = true }) => {
  const [videoOpen, setVideoOpen] = useState(false)

  const toggleVideoPopup = useCallback((state?: SyntheticEvent | boolean) => {
    setVideoOpen(current => typeof state === 'boolean' ? state : !current)
  }, [])


  return (
    <>
      <VideoContainer
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation()
          e.nativeEvent.stopImmediatePropagation()
          toggleVideoPopup(true)
        }}
      >
        <VideoContainerInner>
          <VideoContainerAutoPlay fit={fit} src={videoSrc} autoPlay={autoPlay} muted loop></VideoContainerAutoPlay>
          <VideoContainerButton
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              toggleVideoPopup(true)
            }}
          >
            Watch Video
          </VideoContainerButton>
        </VideoContainerInner>
      </VideoContainer>
      {videoOpen && (
        <VideoPlayerOverlay
          videoLink={videoSrc}
          handlePopupClose={toggleVideoPopup}
        />
      )}
    </>
  )
}
