import React from 'react';
import SbEditable from 'storyblok-react';
import truncate from 'lodash/truncate';

import { getFluidGatsbyImage } from '../utils/gatsby-storyblok-image-fixed';

import Intro3 from './intro3';
import Intro3WithVideo from './intro3WithVideo';
import AboutProject from './about-project';
import DetailsSteps from './details-steps';
import SectionCarousel from './section-carousel';
import RichtextParser from '../utils/richtext-parser';
import StoryblokComponent from './storyblok/storyblok-component';
import { Box } from './UIKit/system';
import { useStaticQuery, graphql } from 'gatsby'

function CaseStudy({
  about,
  additionalContent,
  caseStudyStages,
  featuredImage: featuredMedia,
  gallery,
  subtitle,
  title,
}) {
  const fluidFeaturedImage = getFluidGatsbyImage(featuredMedia, {
    maxWidth: 1920,
  });
  const fluidCompanyImage = getFluidGatsbyImage(about[0].companyLogo, {
    maxWidth: 550,
  });

  // We have no media content type field to test, so if the fluid helper returns a blank object then it is likely a video
  const featuredMediaType = fluidFeaturedImage && fluidFeaturedImage.src ? 'image' : 'video'

  const featuredImage = featuredMediaType === 'image' ? fluidFeaturedImage : undefined
  const featuredVideo = featuredMediaType === 'video' ? featuredMedia : undefined

  const galleryImagesParsed = gallery && gallery.map((image) => {
    const fluidImage =  getFluidGatsbyImage(image, {
      maxWidth: 1080,
    });

    return {
      type: fluidImage.src ? 'image' : 'video',
      ...(fluidImage.src ? fluidImage : image)
    }
  });

  const stagesConv =  caseStudyStages.flatMap(({ stages }) => stages.map((stage) => {
    return {
      text: RichtextParser(stage.paragraph),
      ...stage,
    }
  }))

  const IntroComponent = featuredVideo ? Intro3WithVideo : Intro3

  return (
    <div>
      <IntroComponent
        pagination={[
          {
            link: '/case-studies/',
            name: 'Our Work',
          },
          {
            name: truncate(title, { length: 80 }),
          },
        ]}
        title={title}
        text={subtitle}
        image={featuredImage}
        video={featuredVideo}
      />
      <AboutProject
        text={about[0].infoParagraph}
        specification={about[0].projectStats}
        image={fluidCompanyImage}
        imageTitle={about[0].companyTitle}
        imageDescription={about[0].companyParagraph}
      />
      {galleryImagesParsed && galleryImagesParsed.length > 0 && (
        <Box mb={[-70, null, -80]}>
          <SectionCarousel images={galleryImagesParsed} />
        </Box>
      )}
      <DetailsSteps
        smallPaddingTop
        title={caseStudyStages[0].title}
        sections={stagesConv}
      />
      {additionalContent &&
        additionalContent.map((blok) => {
          return (
            <SbEditable key={blok._uid} content={blok}>
              <StoryblokComponent {...blok} />
            </SbEditable>
          );
        })}
    </div>
  );
}

export default CaseStudy;
