import type { FC, KeyboardEvent } from "react"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getThemeColor, hoverScaleDefault } from '../utils/styles'
import styled, { css } from "styled-components"
import mq from '../utils/mq'
import { LucidePause, LucidePlay, LucideVolumeOff, LucideVolume2 } from "lucide-react"

const StyledVideoContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  max-height: 600px;
  overflow: hidden;

  ${mq.mobile(css`
    height: auto;
  `)};

  background-color: #000;
  user-select: all;
`

const StyledVideo = styled.video<Omit<VideoPlayerProps, 'videoSource'>>`
  position: relative;
  z-index: 0;
  display: block;

  width: 100%;
  height: auto;

  ${(props) => css`object-fit: ${props.fit ?? 'cover'};`}

`

const StyledVideoControls = styled.div`
  z-index: 1;

  position: absolute;
  bottom: 1rem;
  right: 1rem;

  display: flex;
  gap: 1rem;
`

const StyledVideoControlsButton = styled.button`
  ${hoverScaleDefault};

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  outline: none;
  border: none;

  z-index: 1;

  cursor: pointer;

  &:hover {
    transform: scale(1.025);

    svg {
      stroke: ${getThemeColor('primary')};
    }
  }

  &:active {
    transform: scale(1.05);
  }

  &:focus-visible {
    transform: scale(1.05);
    outline: solid 2px ${getThemeColor('primary')};

    svg {
      stroke: ${getThemeColor('primary')};
    }
  }

  svg {
    height: 20px;
    width: auto;
    position: relative;

    stroke: ${getThemeColor('black')}

    &.icon-play {
      left: 2px;
    }
  }
`

interface VideoPlayerProps {
  /**
   * The video source - can either be the URL to a Storyblok asset (i.e. `https://a.storyblok.com/path/to/video.mp4`),
   * or the URL of an external video hosted on YouTube/Storyblok.
   */
  videoSource: string
  /**
   * Choose whether to autoplay the video on load - by default set to true.
   * If true, the video will also be muted, and set to loop.
   */
  autoPlay?: boolean
  /**
   * Set how the video is displayed - either contained (`contain`) within the parent container
   * so it scales down to show the whole video, or set to covered (`cover`) where it will scale up
   * to cover the parent container, and may cutoff part of the video.
   */
  fit?: 'contain' | 'cover'
  /**
   * Choose whether to display video controls - this is set to `true` by default.
   */
  controls?: boolean
}

/**
 * A component that shows a video element (either from Storyblok assets or an embed), with associated controls.
 * By default it autoplays on mute, and has action buttons to play/pause + mute/unmute.
 */
export const VideoPlayer: FC<VideoPlayerProps> = ({
  videoSource,
  autoPlay = true,
  fit = 'contain',
  controls = true
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const [isPlaying, setPlaying] = useState<boolean>(autoPlay)
  const [isMuted, setMuted] = useState<boolean>(autoPlay)

  useEffect(() => {
    if (!videoRef.current) return

    videoRef.current.addEventListener('play', () => setPlaying(true))
    videoRef.current.addEventListener('pause', () => setPlaying(false))

    videoRef.current.addEventListener('volumechange', () => {
      if (videoRef.current) {
        setMuted(videoRef.current?.muted)
      }
    })
  }, [videoRef])

  const toggleVideoPlaying = useCallback(() => {
    if (!videoRef.current) return

    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [videoRef])

  const toggleVideoMuted = useCallback(() => {
    if (!videoRef.current) return

    if (videoRef.current.muted) {
      videoRef.current.volume = 1
      videoRef.current.muted = false
    } else {
      videoRef.current.muted = true
      videoRef.current.volume = 0
    }
  }, [videoRef])

  const keyupEnterHandler = useCallback((fn: () => void) => {
    return useCallback((event: KeyboardEvent) => {
      if(event.key === 'Enter' || event.key === ' ') return fn()
    }, [fn])
  }, [])

  return (
    <StyledVideoContainer>
      <StyledVideo
       fit={fit}
       src={videoSource}
       autoPlay={autoPlay}
       muted={autoPlay}
       loop
       controls={false}
       ref={videoRef}
      />
      {controls && (
        <StyledVideoControls>
          <StyledVideoControlsButton
            aria-label={isPlaying ? 'Pause video' : 'Play video'}
            onClick={toggleVideoPlaying}
            onKeyUp={keyupEnterHandler(toggleVideoPlaying)}
          >
            {isPlaying ? <LucidePause /> : <LucidePlay />}
          </StyledVideoControlsButton>
          <StyledVideoControlsButton
            aria-label={isMuted ? 'Unmute video' : 'Mute video'}
            onClick={toggleVideoMuted}
            onKeyUp={keyupEnterHandler(toggleVideoMuted)}
          >
            {isMuted ? <LucideVolumeOff /> : <LucideVolume2 />}
          </StyledVideoControlsButton>
        </StyledVideoControls>
      )}
    </StyledVideoContainer>
  )
}
